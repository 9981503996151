<template>

  <top-nav :activeTab='dataSet'></top-nav>

  <div class="mainContainer container" style="">

    <div class="section">
      <ul>
        <li class="item" v-for="(item,idx) in items" :key="idx" @click="gotoUrl(item.url)">
            <div class="itemImg"><img :src="item.image"></div>
            <div>
              <div class="itemTitle">{{item.title}}</div>
              <div class="itemDesc" v-html="item.desc"></div>
            </div>
        </li>
      </ul>
    </div>
  </div>
</template>



<script>

import TopNav from '@/components/Insights/TopNav';
//import TextClamp from 'vue3-text-clamp';

export default {
  name: 'List',
  components: {
    TopNav,
    //TextClamp

  },
  props:{
    dataSet: String,
    //activeTab: String,
  },

  data(){
    return{
      items:[],
    }
  },
  methods:{
    getData(){
      this.axios
          .get(this.$hostname + '/getdata/?data=' + this.dataSet,{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
              this.items = response.data;

          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },
    gotoUrl(url){
      if(url !== '#') window.open(url);
    }
  },
  mounted() {
    document.title = this.title;
    this.getData();
    let el = document.querySelector('.active');
    el.scrollIntoView(true);
  }
}
</script>

<style scoped>
  .item{
    display:flex;
    justify-content: center;
    align-items: center;
    height:200px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding:10px;
  }

  .itemImg{
    padding:10px;
  }

  .itemImg img{
    max-width:120px;
  }

  .item .itemTitle{
    color:#0e90d2;
    font-weight: bold;
    margin-bottom:5px;
  }

  .item .itemDesc{
    max-height:100px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:11px;
  }

  .mainContainer{
    margin-top:150px;
  }

</style>