<template>
  <div id="topNavDiv">
    <div class="topNav" >
      <div class="topNavTab clickable" :class="{active: activeTab === 'community'}" @click="gotoPage('')">Community</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'articles'}" @click="gotoPage('articles')">Articles</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'books'}" @click="gotoPage('books')">Books</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'parenting'}" @click="gotoPage('parenting')">Parenting</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'courses'}" @click="gotoPage('courses')">Courses</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'tools'}" @click="gotoPage('tools')">Tools</div>
      <div class="topNavTab clickable" :class="{active: activeTab === 'links'}" @click="gotoPage('links')">Links</div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'TopNav',
  props: {
    activeTab:String
  },
  data(){
    return{
    }
  },
  methods:{

    gotoPage(mode){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/insights/' + mode });
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#topNavDiv{
  width:100%;
  height:120px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content:left;
  position:fixed;
  top:0;
  z-index: 9;
  border-bottom: 0.5px solid #ffd603;
}


.topNav{
  display: flex;
  justify-content: left;
  overflow: scroll;

}

.topNav::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.topNav .topNavTab{
  border-radius:2px 2px 0 0 ;
  padding:10px;
  margin:0 2px;
  white-space: nowrap;
  background-color: #ffffff;
  font-size:13px;
}

.topNav .topNavTab.active{
  background-color: #ffd603;
}
</style>
